.message-list {
  max-width: 500px;
  width: 100%;
}

.message-container {
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  border-bottom: 1px solid #eeeeee;
}


.user {
  min-width: 120px;
}


::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 20px var(--blue-gov);
  border-radius: 10px;
}


.chat-box {
  overflow-y: scroll;
  min-height: 50vh;
  max-height: 50vh;
  min-width: 40vw;
  max-width: 40vw;

}


.button-reponse  {
  background-color: white; /* Blue background color */
  border-radius: 5px; /* Curve of border corners */
  color: #6699FF; /* Text color */
  margin-right: 6px;
  margin-bottom: 6px;
  border-style: solid ;
  border-width: thin;
  border-color: #6699FF;
  font-size: 16px;
  padding: 0.2rem 0.2rem;
}


.button-reponse:hover{
  background-color: var(--blue-gov);
  color: black;
  border-color: var(--blue-gov);
}



.links {
  display: flex;
  justify-content: center;
  color: var(--blue-gov);
  background-color: white;
  text-decoration: none;
  border-radius: 5px;
  padding: 1%
}

.links:hover{
  background-color: #93d7a3;
  color: black;

}
.custom-div {
  display: flex;
}

.custom-div-image {
  align-items: baseline;
}


.button-reponse-div {
  display: contents;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-top: 10px;
}

.messages {
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow-y: scroll;
  height: 500px;
  /*min-width: 25vw;
  max-width: 25vw;/*
  /*min-height: 20vh;
  max-height: 50vh;*/
  scroll-behavior: auto;
}


.message {
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 0.5rem 0.5rem;
  border-radius: 10px;
  word-wrap: break-word;
  margin-bottom: 10px;
  margin-top: 10px
}




.received {
  background: white;
  color: #4f5051;
  border-color: var(--blue-2);
  border-top-left-radius: 0;
  /*margin-right: auto;*/
  /*align-self: flex-start;*/
  flex-direction: row-reverse;
  white-space: pre-wrap;
  transform-origin: 0 100%;
  animation: message-ani 0.6s ease-out 0s forwards;

}


.sent {
  background: #6699FF;
  color: white;
  border-bottom-right-radius: 0;
  align-self: flex-end;
  justify-content: end;
  /*transform-origin: 0 100%;
  animation: message-ani 0.6s ease-out 0s forwards;*/
}

.estado {
  background: #b9cfd6;
  align-self: center;
  text-align: center;
  color: white;
}

@keyframes message-ani {
  0% {
    /*max-height: 100vmax;*/
    transform: scale(0.3);
  }
  20% {
    /*max-height: 100vmax;*/
    transform: scale(0.9);
  }
  80% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
    max-height: 100vmax;
    overflow: visible;
  }
}