.chat-input-form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}




.input-form {
  width: 80%;
  height: 50px;
  padding: 0rem;
  border-radius: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-style: hidden;
  text-align: center;

}

.button-form {
  width: 20%;
  height: 50px;
  border-radius: 25px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-style: hidden;
  background-color: white;
  border-top-right-radius: 0;
}



.button-reponse  {
  background-color: white; /* Blue background color */
  border-radius: 5px; /* Curve of border corners */
  color: #6699FF; /* Text color */
  margin-right: 6px;
  margin-bottom: 6px;
  border-style: solid ;
  border-width: thin;
  border-color: #6699FF;
  font-size: 16px;
  padding: 0.2rem 0.2rem;
}

.button-reponse:hover{
  background-color: var(--blue-2);
}



.button-suggest {
  background-color: #6699FF;
  /* Blue background color */
  color: white; /* Text color */
  margin-right: 6px;
  margin-bottom: 6px;
  border-width: thin;
  border: 0px;
  /* Text color */
  padding: 0.5rem 0.5rem;
  border-radius: 15px;
  /*min-height: 24px;
  max-height: 24px;*/
}

.button-suggest:hover {
  background-color: var(--blue-2);
  color: black;
  border-color: var(--blue-gov);
}

.no-suggestions {
  width: 100%;
  /*max-height: 5vmin;
  min-height: 5vmax;
  padding: 1%;*/
}

.subtitle {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 10px;
}

.suggestions {
  background-color: #D6E2EA;
  border: hidden;
  padding: 10px;
  /*overflow: auto;*/
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  max-height: 160px;
  border-top: 1px solid #C0C7CC;
  text-align: center;
}

.suggestions::-webkit-scrollbar {
  width: 0.2rem;
  height: 0rem;
  border-radius: 20px;
}

.suggestions::-webkit-scrollbar-thumb {
  background-color: hsla(218, 100%, 42%, 1);
}

.suggest-title {
  width: 100%;
  color: #919EA8;
  margin-top: 0;
}

.suggestions li {
  padding: 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
}

.suggestion-active,
.suggestions li:hover {
  background: linear-gradient(90deg,
      hsla(218, 100%, 42%, 0.6) 0%,
      hsla(0, 0%, 98%, 1) 200%);
  color: #fff;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}