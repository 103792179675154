.main-content {
  width: 350px;
  margin-top: 40px;
}
.app-header {
  padding: 10px;
  background-color: #282c34;
  color: white;
}

.div-ref {
  margin-top: 30px;
  justify-content: center;
  display: flex;
}

.span-chat {
  position: relative;
  justify-content: center;
  padding: 5px 10px 0px 10px;
  align-items: center;
  font-size: 14px;
  height: 68px;
  background-color: white;
  color: black;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}


.close-chat {
  height: 30px;
  width: 30px;
  color: #70858C;
  background-color: white;
  border-color: black;
  cursor:pointer;
  border-style: hidden;
  font-size: 15px;
  padding: 5px;
  /*align-content: flex-end;*/
  margin-left: auto;
  border-radius: 50px;
}

.imagen-logo {
  margin-right: 10px;
}

.titulo-area {
  margin-left: 2%;
}

.texto-titulo {
  font-size: 1.1rem;
  color: #244151;
  font-weight: 500;
}

.texto-status {
  font-size: 0.8rem;
  color: #B3B3B3;
}

.chat-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #DFEAF4;
  padding: 0px 5px 0px 10px;
}

.title-chat {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
}

.conect-button {
  display: flex;
  flex-wrap: wrap;
  font-size: 20px;
  align-content: center;
  margin-top: 50vh;
}

.open-chat-button {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
  background-color: white;
  /* Blue background color */
  border-radius: 5px;
  /* Curve of border corners */
  color: var(--blue-color);
  /* Text color */
  border-style: solid;
  border-width: thin;
  border-color: var(--blue-color);
  font-size: 36px;
  padding: 1rem 1rem;
}




.autocomplete-container {
  width: 100%;
}

.button-autocomplete {
  background-color: white;
  /* Blue background color */
  border-radius: 5px;
  /* Curve of border corners */
  color: var(--blue-gov);
  /* Text color */
  width: 90%;
  height: 40px;
  margin-right: 6px;
  margin-bottom: 6px;
  border-style: solid;
  border-width: thin;
  border-color: var(--blue-gov);
  font-size: 16px;
  padding: 0.2rem 0.2rem;
  justify-content: center;
  justify-items: center;
}

.button-autocomplete:hover{
  background-color: 	#90EE90;
}

.enable-disable {
  background-color: #58b795;
  color: white;
  border-style: hidden;
  justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 5px 5px;
}

.enable-disable:hover{
  color: white;
  background-color: #58b795;
}


body {
  --white-color: #f3f4fb;
  --black-color: black;
  --blue-color: #5a18ee;
  --light-purple-color: #7f7afc;
  --light-violet-color: #8c54f4;
  --darker-violet-color: #713dc3;
  --dark-violet-color: #5d2db0;
  --grey-color: #ebe6e6;
  --pink-color: #FFC0CB;
  --red-color: #FF0000;
  --blue-gov: #03078E;
  --blue-2: #c2d1ef;
  --red-2: #F08080;
  --pink: #e83e8c;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  background-color: var(--grey-color);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content-button-suggest {
  display: flex;
  flex-direction: row;
  justify-content: center;
}